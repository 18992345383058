/*
 * @Author: wangding
 * @Date: 2020-08-27 12:13:28
 * @LastEditTime: 2020-11-23 15:03:10
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \SolaxCloud\src\bus.js
 */
/**
 * Created by bootdo.
 */
import Vue from 'vue'

export let bus = new Vue()