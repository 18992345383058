<!--
 * @Author: your name
 * @Date: 2020-11-23 17:01:55
 * @LastEditTime: 2021-04-01 16:58:27
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solax-design\src\views\login\login.vue
-->
<template>
    <div class="login-mian-box">
        <div class="mian-box">
            <div class="header-logo">
                <div>
                    <div class="logo">
                    </div>
                    <div class="title">
                        Solax Design
                    </div>
                </div>
                <el-dropdown @command="handleCommand">
                    <span class="el-dropdown-link" style="color:#666;font-size: 18px;cursor:pointer">
                        {{$t('lang.select')}}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="zh_CN">中文</el-dropdown-item>
                        <el-dropdown-item command="en_US">English</el-dropdown-item>
                        <el-dropdown-item command="ja_JP">日本語</el-dropdown-item>
                        <el-dropdown-item command="fr_FR">Français</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <div class="logo-box">
                <div class="tabs-box">
                    <div :class="!(active == 1)?'active':''" @click='active=1'>{{$t('operate.user.login')}}</div>
                    <div :class="!(active == 2)?'active':''" @click='active=2'>{{$t('user.register')}}</div>
                </div>
                <transition name="slide-fade">
                    <div class="logo-box-center" v-if='active == 1'>
                        <el-row :gutter="20">
                            <el-col :span="17" :offset="3">
                                <el-form ref="form" :model="form" label-width="120px" >
                                    <el-form-item class="form-item-style" :label="$t('user.email')" prop="loginName" :error="emailError"
                                                  :rules="[{ required: true, message: $t('remind.not-null')}]">
                                        <el-input v-model="form.loginName"   ></el-input>
                                    </el-form-item>
                                    <el-form-item class="form-item-style" :label="$t('user.password')" prop="password" :rules="[{ required: true, message: $t('remind.not-null')}]">
                                        <el-input type="password" v-model="form.password" ></el-input>
                                    </el-form-item>
                                </el-form>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20" style="margin-top:25px;">
                            <el-col :span="14" :offset="6">
                                <div class="password-manage">
                                    <el-checkbox v-model="saveName">{{$t('index.remember.me')}}</el-checkbox>
                                    <a href="javascript:;" class="a-targen-style" @click='dialogVisible=true'>{{$t('index.forget.your.password')}}</a>
                                </div>
                            </el-col>
                        </el-row>
                        <!-- <el-row :gutter="20" style="margin-top:25px;text-align: center;">
                            <span style="color:red;">{{errorMsg}}</span>
                        </el-row> -->
                        <el-row :gutter="20" style="margin-top:25px;">
                            <el-col :span="14" :offset="6">
                                <div class="login-button" @click='save("form")'>
                                    {{$t('user.login')}}
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </transition>
                <transition name="slide-fade2">
                    <div class="logo-box-center" v-if='active == 2'>
                        <el-row :gutter="20">
                            <el-col :span="17" :offset="3">
                                <el-form ref="formR" :model="formR" label-width="160px" >
                                    <el-form-item class="form-item-style2" :label="$t('user.email')" prop="loginName" :rules="[{ required: true, message: $t('remind.not-null')},{validator:test_email,trigger:'blur'}]">
                                        <el-input v-model="formR.loginName" ></el-input>
                                    </el-form-item>
                                    <el-form-item class="form-item-style2" :label="$t('user.password')" prop="password" :rules="[{ required: true, message: $t('remind.not-null')},{min:6,max:20,message:$t('index_js_lengthRules')}]">
                                        <el-input type="password" v-model="formR.password" ></el-input>
                                    </el-form-item>
                                    <el-form-item class="form-item-style2" :label="$t('user.re-typepassword')" prop="passwordR" :rules="[{ required: true, message: $t('remind.not-null')},{validator:test_regPaw,trigger:'blur'}]">
                                        <el-input type="password" v-model="formR.passwordR" ></el-input>
                                    </el-form-item>
                                    <el-form-item class="form-item-style2" prop="read" :rules="[{ required: true, message: $t('palase.select')}]">
                                        <el-checkbox v-model="formR.read">{{$t('user.read-agree')}}<a href="javascript:;" class="a-targen-style" @click="()=>{$store.commit('setVisibleAdd',true)}">{{$t('index.disclaimer')}}</a></el-checkbox>
                                    </el-form-item>
                                </el-form>
                            </el-col>
                        </el-row>
                        <!-- <el-row :gutter="20" style="margin-top:25px;">
                            <el-col :span="14" :offset="6">
                                <div>
                                    <el-checkbox v-model="read">{{$t('user.read-agree')}}<a href="javascript:;" class="a-targen-style" @click="()=>{$store.commit('setVisibleAdd',true)}">{{$t('index.disclaimer')}}</a></el-checkbox>
                                </div>
                            </el-col>
                        </el-row> -->
                        <el-row :gutter="20" style="margin-top:25px;">
                            <el-col :span="14" :offset="6">
                                <div class="login-button" @click="saveR('formR')">
                                    {{$t('common.button.register')}}
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </transition>
            </div>
        </div>
        <el-dialog
        :title="$t('user.forget.password')"
        :visible.sync="dialogVisible"
        width="30%"
        :close-on-click-modal='false'
        >
            <p style="text-align: center;">{{$t('user.enter-register-email')}}</p>
            <div class="email-box-modal">
                <el-input v-model="email"  style="width:160px;"></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">{{$t('common.button.cancel')}}</el-button>
                <el-button type="primary" @click="systemForgetPassword()">{{$t('common.button.ok')}}</el-button>
            </span>
        </el-dialog>
        <el-dialog
        :title="$t('setupNewPassword')"
        :visible.sync="dialogVisibleEdit"
        width="30%"
        :close-on-click-modal='false'
        >
            <div class="email-box-modal">
                <el-form :model="resetPassword" ref="resetPaw" label-width="180px" >
                    <el-form-item :label="$t('user.enter-new-password')" prop="password1" :rules="[{ required: true, message: $t('remind.not-null')},{min:6,max:20,message:$t('index_js_lengthRules')}]">
                            <el-input type="password" v-model="resetPassword.password1"  :placeholder="$t('info.please.input.password')" style="width:180px;"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('userInfo.new.password.again')" prop="password2"  :rules="[{ required: true, message: $t('remind.not-null')},{validator:reset_regPaw,trigger:'blur'}]">
                            <el-input type="password" v-model="resetPassword.password2" :placeholder="$t('info.please.input.password')" style="width:180px;"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisibleEdit = false">{{$t('common.button.cancel')}}</el-button>
                <el-button type="primary" @click="resetpaw('resetPaw')">{{$t('common.button.ok')}}</el-button>
            </span>
        </el-dialog>
        <agreementModal></agreementModal>
    </div>
</template>

<script>
import agreementModal from './agreement.vue'
export default {
    components: {
        agreementModal  
    },
    props: {

    },
    data() {
        this.test_email = async(rule, value, callback) => {
            if(value!=''&&!this.Regs.email_reg.test(value)){
                callback(new Error(this.$t('login.js_sign_email_error')));
            }else{
               callback();
            }
        }
        this.test_regPaw = (rule, value, callback)=>{
            if(value != this.formR.password){
                callback(new Error(this.$t('user.msg_different_twice')))
            }else{
                callback()
            }
        }
        this.reset_regPaw = (rule, value, callback)=>{
            if(value != this.resetPassword.password1){
                callback(new Error(this.$t('user.msg_different_twice')))
            }else{
                callback()
            }
        }
        return {
            emailError:'',
            pwdError:'',
            active:1,
            form:{
               loginName:'',
               password:''
            },
            formR:{
               loginName:'',
               password:'',
               passwordR:'',
               read:false,
            },
            saveName:false,
            dialogVisible:false,
            dialogVisibleEdit:false,
            email:'',
            errorMsg:'',
            resetPassword:{
                password1:'',
                password2:'',
            }
        };
    },
    computed: {

    },
    watch: {

    },
    created() {

    },
    mounted() {
        window.addEventListener('keydown',this.keyDown)
        if(this.$route.query){
            if(this.$route.query.tokenStatus){
                this.$notify({
                    title: this.$t('message'),
                    message: this.$t('login.out'),
                    duration: 2000
                });
            }
        }
        if(this.$route.query && this.$route.query.code){
            if(this.$route.query.type == 1){
                this.dialogVisibleEdit = true
            }else{
                this.systemConfirm()
            }
        }
        if(localStorage.getItem('loginName')){
            this.form.loginName = localStorage.getItem('loginName')
            this.form.password = localStorage.getItem('password')
        }
        if(localStorage.getItem('saveName')){
            this.saveName = JSON.parse(localStorage.getItem('saveName'))
        }
    },
    destroyed(){
        window.removeEventListener('keydown',this.keyDown,false)
    },
    methods: {
        keyDown(e){
            if(e.keyCode==13){
                if(this.active==1){
                    this.save("form")
                }else{
                    this.saveR('formR')
                }
            }
        },
        handleCommand(lang){
            // this.$i18n.locale=lang;
            localStorage.setItem('lang',lang);
            localStorage.setItem('lan',lang);
            location.reload();
        },
        //修改密码
        async systemResetPassword(){
            let res = await this.API.systemResetPassword({
                password:this.resetPassword.password1,
                confirmPassword:this.resetPassword.password2,
                code:this.$route.query.code,
            })
            if(res.code == 1){
                this.errorMsgBox(this.$t('login.message1'))
            }else if(res.code == 2){ 
                this.errorMsgBox(this.$t('login.message2'))
            }else if(res.code == 3){
                this.errorMsgBox(this.$t('login.message3'))
            }else if(res.code == 4){
                this.errorMsgBox(this.$t('login.message4'))
            }else{
                this.$message({
                message: this.$t('operate.change.password.success'),
                type: 'success'
            })
            this.dialogVisibleEdit = false
            this.$route.query.type = 10
            }
            
        },
        async systemEnterNewPassWord(){
            let res = await this.API.systemEnterNewPassWord({
                code:this.$route.query.code
            })
            if(res.code == 1){
                this.errorMsgBox(this.$t('login.message1'))
            }else if(res.code == 2){
                this.errorMsgBox(this.$t('login.message2'))
            }else if(res.code == 3){
                this.errorMsgBox(this.$t('login.message3'))
            }else if(res.code == 500){
                this.errorMsgBox(this.$t('operate.result.fail'))
            }
        },
        //忘记密码发送邮件
        async systemForgetPassword(){
            let res = await this.API.systemForgetPassword({
                email:this.email
            })
            if(res.code == 1){
                this.errorMsgBox(this.$t('login.message5'))
            }
            if(res.code == 2){
                this.errorMsgBox(this.$t('login.message6'))
            }
            if(res.code == 3){
                this.errorMsgBox(this.$t('login.message7'))
            }
            if(res.code == 4){
                this.errorMsgBox(this.$t('login.message8'))
            }
            if(res.code == 0){
                this.$message({
                    message: this.$t('login.message14'),
                    type: 'success'
                })
            }
            this.dialogVisible = false
        },
        async systemConfirm(){
            let res = await this.API.systemConfirm({
                params:{code:this.$route.query.code}
            })
            if(res.code == 1){
                this.errorMsgBox(this.$t('login.message1'))
            }else if(res.code == 2){
                this.errorMsgBox(this.$t('login.message2'))
            }else if(res.code == 3){
                this.errorMsgBox(this.$t('operate.result.fail'))
            }else if(res.code == 500){
                this.errorMsgBox(this.$t('operate.result.fail'))
            }else{
                this.$message({
                message: this.$t('operate.result.success'),
                type: 'success'
            })
            }

        },
        async systemRegisterUser(){
            if(this.formR.password !=this.formR.passwordR){
                this.$message({
                    message: this.$t('login.message4'),
                    type: 'warning'
                })
                return
            }
            if(!this.formR.read){
                this.$message({
                    message: this.$t('user.read-and-agree-license'),
                    type: 'warning'
                })
                return
            }
            let res = await this.API.systemRegisterUser({
                email:this.formR.loginName,
                password:this.formR.password,
                confirmPassword:this.formR.passwordR,
                
            })
            if(!res.result){
                if(res.code == 1){
                    this.errorMsgBox(this.$t('login.message9'))
                }
                if(res.code == 2){
                    this.errorMsgBox(this.$t('login.message10'))
                }
                if(res.code == 3){
                    this.errorMsgBox(this.$t('login.message11'))
                }
                if(res.code == 4){
                    this.errorMsgBox(this.$t('login.message12'))
                }
                if(res.code == 5){
                    this.errorMsgBox(this.$t('login.message13'))
                }
                if(res.code == 6){
                    this.errorMsgBox(this.$t('login.message4'))
                }
                if(res.code == 0){
                    this.$message({
                        message: this.$t('login.message14'),
                        type: 'success'
                    })
                }
            }else{
                this.$message({
                    message: this.$t('operate.result.success'),
                    type: 'success'
                })
            }
            
        },
        save(formName){
            this.$refs[formName].validate((valid) => {
              if (valid) {
                  this.systemLogin()
              } else {
                return false;
              }
            });
        },
        saveR(formName){
            this.$refs[formName].validate((valid) => {
              if (valid) {
                  this.systemRegisterUser()
              } else {
                return false;
              }
            });
        },
        resetpaw(formName){
            this.$refs[formName].validate((valid) => {
              if (valid) {
                  this.systemResetPassword()
              } else {
                return false;
              }
            });
        },
        async systemLogin(){
            this.emailError = ''
            let res = await this.API.systemLogin({
                email:this.form.loginName,
                password:this.form.password
            })
            if(res.code == 1){
                /*this.$message({
                    message: '账号或者密码错误！',
                    type: 'warning'
                })*/
                this.emailError = this.$t('operate.user.login.no.matched.user')
            }else if(res.code == 0){
                // this.$message({
                //     message: this.$t('operate.result.success'),
                //     type: 'success'
                // })
                if(this.saveName){
                    localStorage.setItem('loginName',this.form.loginName)
                    localStorage.setItem('password',this.form.password)
                    localStorage.setItem('saveName',JSON.stringify(this.saveName))
                }
                if(!(localStorage.getItem('lang') || localStorage.getItem('lan'))){
                    localStorage.setItem('lang','en_US');
                    localStorage.setItem('lan','en_US');
                }
                sessionStorage.setItem('token',res.token)
                sessionStorage.setItem('userType',res.user.userType)
                sessionStorage.setItem('userInfo',JSON.stringify(res.user))
                this.$router.push({path:'/home'})
            }
        },
        errorMsgBox(msg){
            this.$message({
                message: msg,
                type: 'warning'
            })
        }
    },
};
</script>

<style  lang="less" >
.login-mian-box{
    background: url(../../assets/img/login-bg5.jpg) no-repeat center center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-size: cover;
    .header-logo{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .el-form-item::before{
        content: none !important;
    }
    .el-form-item__label:before{
        content: none !important;
    }
    .mian-box{
        width: 85%;
        margin: 0 auto;
        padding-top: 30px;
        // position: relative;
        .logo{
            float: left;
            width: 175px;
            height: 51px;
            background: url(../../assets/img/logo.png) no-repeat left top;
        }
        .title{
            font-size: 29px;
            border-left: 1px solid #999;
            padding-left: 20px;
            margin: 15px 20px;
            display: inline-block;
            color: #fff;
            font-style:oblique;
        }
        .logo-box{
            width: 600px;
            height: 400px;
            background: rgba(0, 0, 0, .6);
            border-radius: 10px;
            // position: absolute;
            // top: 40%;
            // left: 50%;
            // transform: translate(-50%,-50%);
            margin: 0 auto;
            margin-top: 65px;
            position: relative;
            .tabs-box{
                color:#fff;
                display: flex;
                justify-content: space-evenly;
                font-size: 22px;
                div{
                    flex: 1;
                    text-align: center;
                    padding: 10px 0px;
                }
                .active{
                    background: rgba(0, 0, 0, .7);
                    border-radius: 10px;
                }
            }
            .logo-box-center{
                color: #fff;
                position: absolute;
                // float: left;
                width: 600px;
                .el-form-item__label{
                    color: #fff;
                    font-size: 16px;
                }
                .form-item-style{
                    margin-top:50px; 
                }
                .form-item-style2{
                    margin-top:30px; 
                }
                .el-checkbox__label{
                    color: #fff;
                }
                .password-manage{
                    display: flex;
                    justify-content:space-between;
                    align-items: center;
                }
                .a-targen-style{
                    color: #FFAF40;
                    text-decoration: none;
                    font-size: 14px;
                }
                .login-button{
                    text-align: center;
                    height: 40px;
                    line-height: 40px;
                    background-color: #FFAF40;
                    border-radius: 7px;
                    cursor: pointer;
                }
            }
        }
    }
    .email-box-modal{
        text-align: center;
        margin-top:15px;
    }
    .slide-fade-enter-active {
        transition: all .5s ease;
    }
    .slide-fade-leave-active {
        transition: all .3s ease;
    }
    .slide-fade-enter,.slide-fade-leave-to{
        transform: translateX(-200px);
        opacity: 0;
    }
    .slide-fade2-enter-active {
        transition: all .5s ease;
    }
    .slide-fade2-leave-active {
        transition: all .3s ease;
    }
    .slide-fade2-enter,.slide-fade2-leave-to{
        transform: translateX(200px);
        opacity: 0;
    }
}
.el-form .el-form-item--small .el-form-item__error{
    width: 62px;
}
.el-form-item__error{
    // word-break:break-word;
    //  width: 150px;
    width: max-content !important;
}
</style>

