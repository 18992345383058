/**
 * Created by bootdo.
 */
import axios from 'axios'
//进度条
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

axios.defaults.withCredentials = true;
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'; //配置请求头
// if (sessionStorage.getItem('token')) {
//     axios.defaults.headers.common['Authorization'] = res.headers.authorization
// }


import {
    bus
} from '../bus.js'
let time = null;
let code = null;
axios.interceptors.request.use(
    config => {
        //请求头需要带token
        let loginUserOnlyCode = null;
        let obj = {};
        let url = window.location.href;
        if (url.indexOf("?") != -1) {
            var str = url.split("?")[1];
            var strs = str.split("&");
            for (var i = 0; i < strs.length; i++) {
                obj[strs[i].split("=")[0]] = (strs[i].split("=")[1]);
            }
            loginUserOnlyCode = obj['loginUserOnlyCode']
        }

        if (config.params) {
            config.params.loginUserOnlyCode = loginUserOnlyCode;
        } else {
            config.params = {
                loginUserOnlyCode: loginUserOnlyCode
            }
        }
        if (window.sessionStorage.getItem('token')) {
            config.headers['token'] = window.sessionStorage.getItem('token');
            config.headers['lang'] = window.localStorage.getItem('lang');
        }
        NProgress.start()
        return config
    },
    error => {
        return Promise.reject(error)
    }
);
// 添加一个响应拦截器
axios.interceptors.response.use(function(response) {
    if (response.data && response.data.code && response.data.code == '402') {
        //未登录或者登陆过期
        bus.$emit('test', 11)
    }
    NProgress.done()
    return response;
}, function(error) {
    // Do something with response error
    return Promise.reject(error);
});

//基地址 1:国内 0：国外
let base = process.env.VUE_APP_BASE_API_LOCAL
    //let base = 'http://10.5.0.201:8080/design'
    // console.log(base, 'base');
    //通用方法
export const POST = (url, params) => {
    return axios.post(`${base}${url}`, params).then(res => res.data)
}
export const POST1 = (url, params) => {
    return new Promise((resolve, reject) => {
            axios.post(`${base}${url}`, params,
                // 将axios 对象obj数据的post请求转化为formdata格式
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'token': sessionStorage.getItem('token') || ''
                    },
                    timeout: 80000
                }
            ).then(res => {
                resolve(res.data);
            }).catch((error) => {
                reject(error)
            })
        })
}
//通用方法
export const POST2 = (url, params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${base}${url}`, params,
            // 将axios 对象obj数据的post请求转化为formdata格式
            {
                transformRequest: [function(data) {
                    var str = [];
                    for (var p in data) {
                        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(data[p]));
                    }
                    return str.join("&");
                }],
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'token': sessionStorage.getItem('token') || ''
                },
                timeout: 80000
            }
        ).then(res => {
            resolve(res.data);
        }).catch((error) => {
            reject(error)
        })
    })
}

//上传文件调用
export const POST3 = (url, params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${base}${url}`, params,
            // 将axios 对象obj数据的post请求转化为formdata格式
            {
                transformRequest: [function(data) {
                    let formData = new FormData();
                    for (var p in data) {
                        formData.append(p, data[p] == null ? '' : data[p]);
                    }
                    return formData;
                }],
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'token': sessionStorage.getItem('token') || ''
                },
                timeout: 80000
            }
        ).then(res => {
            resolve(res.data);
        }).catch((error) => {
            reject(error)
        })
    })
}
// export const POST3 = (url, params) => {
//     return new Promise((resolve, reject) => {
//         axios.post(`${base}${url}`, params,
//             // 将axios 对象obj数据的post请求转化为formdata格式
//             {
//                 transformRequest: [function(data) {
//                     let formData = new FormData();
//                     for (var p in data) {
//                         //把特殊字符进行转码，文件类型的不需要转码
//                         formData.append(p, data[p] instanceof File ? data[p] : data[p] === null ? '' : data[p]);
//                     }
//                     return formData;
//                 }],
//                 headers: {
//                     'Content-Type': 'application/x-www-form-urlencoded',
//                 },
//                 timeout: 800000
//             }
//         ).then(res => {
//             resolve(res.data);
//         }).catch((error) => {
//             reject(error)
//         })
//     })
// }

export const GET = (url, params) => {
    return new Promise((resolve, reject) => {
            axios.get(`${base}${url}`, params,
                // 将axios 对象obj数据的post请求转化为formdata格式
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'token': sessionStorage.getItem('token') || ''
                    },
                    timeout: 80000
                }
            ).then(res => {
                resolve(res.data);
            }).catch((error) => {
                reject(error)
            })
        })
        // return axios.get(`${base}${url}`, {
        //     params: params
        // }, {
        //     headers: {
        //         'Content-Type': 'application/x-www-form-urlencoded',
        //         'token': sessionStorage.getItem('token') || ''
        //     },
        //     timeout: 80000
        // }).then(res => res.data)
}

export const GET1 = (url, params) => {
    return new Promise((resolve, reject) => {
            axios.get(`${base}${url}`,
                // 将axios 对象obj数据的post请求转化为formdata格式
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'token': sessionStorage.getItem('token') || ''
                    },
                    params:params,
                    timeout: 80000
                }
            ).then(res => {
                resolve(res.data);
            }).catch((error) => {
                reject(error)
            })
        })
}
export const PUT = (url, params) => {
    return axios.put(`${base}${url}`, params).then(res => res.data)
}

export const DELETE = (url, params) => {
    return axios.delete(`${base}${url}`, {
        params: params
    }).then(res => res.data)
}

export const PATCH = (url, params) => {
    return axios.patch(`${base}${url}`, params).then(res => res.data)
}
export const FILEUPLOAD = (url) => {
        return `${base}${url}`;
    }
    //不带进度条的
export const uploadFile2 = (url, params) => {
        return axios.post(`${base}${url}`, params, { headers: { 'Content-Type': 'Multipart/form-data' } }).then(res => res.data)
    }
    //带进度条的
export const uploadFile3 = (url, params, rogress) => {
    return axios.post(`${base}${url}`, params, {
        headers: { 'Content-Type': 'Multipart/form-data' },
        onUploadProgress: rogress.onUploadProgress,
        timeout: 1000 * 60 * 10
    }).then(res => res.data)
}
export const downloadFile = (url, params) => {
    window.location.href = `${base}${url}?list=${params}`;
}