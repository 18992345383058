/*
 * @Author: yuanqiao
 * @Date: 2023-3-22 17:34:22
 * @LastEditTime: 2023-3-22 17:34:22
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solaxcloud\src\api\yqContent.js
 */
import * as API from './'
export default {
     // 获取电网电压
     getAllPowerGrids: (params) => {
        return API.POST3('/system/tpowergrid/getAllPowerGrids', params)
    },
    // 获取电池主控列表
    getBmsTypeList: (params) => {
        return API.POST3('/system/tBatterycompatible/getBmsType', params)
    },
}