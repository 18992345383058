import Vue from 'vue'
const obj = {
  install () {
    // Vue.prototype.$属性 = 值
    // 全局自定义指令
    Vue.directive('number', {
      // 标签被插入到真实DOM上,此方法才会执行
      inserted (el) { // el:指令所在的原生标签
        console.log(el,"el");
        el.querySelector('input').addEventListener('keydown', function(e) {
            let key = e.key
            if (key === 'e') {
                e.returnValue = false
                return false
            }else if (key === 'E') {
                e.returnValue = false
                return false
            }
            return true
        })
      }
    })
  }
}
export default obj