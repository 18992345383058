/*
 * @Author: wangding
 * @Date: 2020-11-09 15:59:20
 * @LastEditTime: 2020-11-09 16:36:16
 * @LastEditors: Please set LastEditors
 * @Description: 抽出i18n国际化，以便于在comm.js内调用
 * @FilePath: \solaxcloud\src\service\lan.js
 */
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import jaLocale from 'element-ui/lib/locale/lang/ja'
import ElementLocale from 'element-ui/lib/locale'
import frLocale from 'element-ui/lib/locale/lang/fr'
Vue.use(VueI18n)
const i18n = new VueI18n({
    locale: localStorage.getItem('lang') || 'en_US', // 语言标识
    //this.$i18n.locale // 通过切换 locale 的值来实现语言切换
    messages: {
        'zh_CN': Object.assign(require('./assets/lang/zh_CN.json'), zhLocale), //中文语言包
        'en_US': Object.assign(require('./assets/lang/en_US.json'), enLocale), //英文语言包
        'ja_JP': Object.assign(require('./assets/lang/ja_JP.json'), jaLocale), //小日本语言包
        'fr_FR': Object.assign(require('./assets/lang/fr_FR.json'), frLocale), //法语语言包
    }
})
ElementLocale.i18n((key, value) => i18n.t(key, value)) //按需导入的elementui组件的国际化，随着i18n改变
export default i18n