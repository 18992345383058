<!--
 * @Author: your name
 * @Date: 2020-11-23 14:45:10
 * @LastEditTime: 2020-12-16 13:57:32
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solax-design\src\App.vue
-->
<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import {
    bus
} from './bus.js'
export default {
  
  mounted(){
    let that = this
    bus.$on('test', function (msg) {
      // that.comm.loginAgain()
      that.$router.push({ path: '/login' ,query:{tokenStatus:true}});
      // this.$notify({
      //     title: '提示',
      //     message: '未登录或者登陆过期',
      //     duration: 0
      //   });
    })
  }
}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: #2c3e50;
}
html,body,#app{
    width: 100%;
    height: 100%;
    min-width: 1360px;
}
*{
  margin: 0;
  padding: 0;
}
</style>
