/*
 * @Author: your name
 * @Date: 2020-11-23 14:45:10
 * @LastEditTime: 2020-12-10 15:10:47
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solax-design\src\router\index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/login/login.vue'

Vue.use(VueRouter)

const routes = [
    { path: "/", redirect: "/login" },
    {
        path: '/login',
        name: 'login',
        component: login
    }, {
        path: '/home',
        name: 'home',
        component: () =>
            import ('../views/home/home.vue'),
        children: [{
            path: '/adminInverter',
            name: 'adminInverter',
            component: () =>
                import ('../views/adminInverter/adminInverter.vue'),
        }, {
            path: '/adminModule',
            name: 'adminModule',
            component: () =>
                import ('../views/adminModule/adminModule.vue'),
        }, {
            path: '/adminCity',
            name: 'adminCity',
            component: () =>
                import ('../views/adminCity/adminCity.vue'),
        }, {
            path: '/addCity',
            name: 'addCity',
            component: () =>
                import ('../views/adminCity/addCity.vue'),
        }, {
            path: '/adminCountry',
            name: 'adminCountry',
            component: () =>
                import ('../views/adminCountry/adminCountry.vue'),
        }, {
            path: '/addCountry',
            name: 'addCountry',
            component: () =>
                import ('../views/adminCountry/addCountry.vue'),
        }, {
            path: '/createInverter',
            name: 'createInverter',
            component: () =>
                import ('../views/adminInverter/createInverter.vue'),
        }, {
            path: '/editInverter',
            name: 'editInverter',
            component: () =>
                import ('../views/adminInverter/editInverter.vue'),
        }, {
            path: '/createModule',
            name: 'createModule',
            component: () =>
                import ('../views/adminModule/createModule.vue'),
        }, {
            path: '/projectList',
            name: 'projectList',
            component: () =>
                import ('../views/oldprojectList/projectList.vue'),
        }, {
            path: '/addProjectList',
            name: 'addProjectList',
            component: () =>
                import ('../views/projectList/addProjectList.vue'),
        }, {
            path: '/editProjectList',
            name: 'editProjectList',
            component: () =>
                import ('../views/oldprojectList/addProjectList.vue'),
        }, {
            path: '/userManage',
            name: 'userManage',
            component: () =>
                import ('../views/userManage/userManage.vue'),
        }, {
            path: '/adminManage',
            name: 'adminManage',
            component: () =>
                import ('../views/adminManage/adminManage.vue'),
        },
        {
            path: '/adminBattery',
            name: 'adminBattery',
            component: () =>
                import ('../views/adminBattery/adminBattery.vue'),
        },
        {
            path: '/addBattery',
            name: 'addBattery',
            component: () =>
                import ('../views/adminBattery/addBattery.vue'),
        },
        {
            path: '/adminEvCharger',
            name: 'adminEvCharger',
            component: () =>
                import ('../views/adminEvCharger/adminEvCharger.vue'),
        },
        {
            path: '/addEvCharger',
            name: 'addEvCharger',
            component: () =>
                import ('../views/adminEvCharger/addEvCharger.vue'),
        },{
            path: '/adminHeatPump',
            name: 'adminHeatPump',
            component: () =>
                import ('../views/adminHeatPump/adminHeatPump.vue'),
        },
        {
            path: '/addHeatPump',
            name: 'addHeatPump',
            component: () =>
                import ('../views/adminHeatPump/addHeatPump.vue'),
        },
        {
            path: '/adminBatteryCompatibility',
            name: 'adminBatteryCompatibility',
            component: () =>
                import ('../views/adminBatteryCompatibility/adminBatteryCompatibility.vue'),
        },
        {
            path: '/addBatteryCompatibility',
            name: 'addBatteryCompatibility',
            component: () =>
                import ('../views/adminBatteryCompatibility/addBatteryCompatibility.vue'),
        },
        {
            path: '/adminDevice',
            name: 'adminDevice',
            component: () =>
                import ('../views/adminDevice/adminDevice.vue'),
        },
        {
            path: '/addDevice',
            name: 'addDevice',
            component: () =>
                import ('../views/adminDevice/addDevice.vue'),
        },
        {
            path: '/adminrecommend',
            name: 'adminrecommend',
            component: () =>
                import ('../views/adminrecommend/adminrecommend.vue'),
        },
        {
            path: '/addrecommend',
            name: 'addrecommend',
            component: () =>
                import ('../views/adminrecommend/addrecommend.vue'),
        }
        ]
    }
]

const router = new VueRouter({
        routes
    })
    //后端暂未独立部署，暂不开启前置导航守卫
router.beforeEach((to, from, next) => {
    // var token = library.getData("userName");
    var token = sessionStorage.getItem('token');
    //如果没登录,都导向登录页
    if (to.path == from.path) {
        return
    }
    if (!token) {
        if (to.path !== '/login') {
            next({ path: '/login' })
        } else {
            next();
        }
    } else {
        if (to.path == '/') {
            next({ path: '/home' });
        } else {
            next();
        }
    }
})
export default router