/*
 * @Author: wangding
 * @Date: 2020-10-08 17:08:27
 * @LastEditTime: 2020-10-22 09:58:08
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solaxcloud\src\store\index.js
 */
import Vue from 'vue'
import Vuex from 'vuex'
import sysStore from './module/sys'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        sysStore
    }
})