/*
 * @Author: wangding
 * @Date: 2020-10-22 09:53:27
 * @LastEditTime: 2020-12-12 15:48:02
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solaxcloud\src\store\module\sys\index.js
 */
const state = {
    visibleAdd: false, //公用--新增
    visibleEdit: false, //公用--编辑
    visibleAdd2: false, //公用--新增2
    visibleEdit2: false, //公用--编辑2
    visibleInfo: false, //公用--查看
    visibleBatteryInfo: false, //公用--查看电池
    visibleEvChargerInfo:false,
    visibleAdapterInfo:false,
    visibleDeviceInfo:false,
    visibleBind: false, //公用--绑定
    visibleBind2: false, //公用--绑定2
    visibleShow: false, //公用--显示
    visibleShow2: false, //公用--显示2
    visibleUserInfo: false, //修改用户资料框
    visibleReport: false, //报告设置
    projectActive: 1,
    poFormData: {}, //项目数据
    invFormData: {},
    pvArraysInv: [],
    isJapen:false,
}
const mutations = {
    setIsJapen(state, val){ // 是不是日本区域
        state.isJapen = val
    },
    setpvArraysInv(state, val) { //公用--新增
        state.pvArraysInv = val
    },
    setPoFormData(state, val) { //公用--新增
        state.poFormData = val
    },
    setInvFormData(state, val) { //公用--新增
        state.invFormData = val
    },
    setProjectActive(state, val) { //公用--新增
        state.projectActive = val
    },
    setVisibleAdd(state, val) { //公用--新增
        state.visibleAdd = val
    },
    setVisibleEdit(state, val) { //公用--编辑
        state.visibleEdit = val
    },
    setVisibleAdd2(state, val) { //公用--新增2
        state.visibleAdd2 = val
    },
    setVisibleEdit2(state, val) { //公用--编辑2
        state.visibleEdit2 = val
    },
    setVisibleInfo(state, val) { //公用--查看
        state.visibleInfo = val
    },
    setVisibleBatteryInfo(state, val) { //--查看电池
        state.visibleBatteryInfo = val
    },
    setVisibleDeviceInfo(state, val) { //-查看设备
        state.visibleDeviceInfo = val
    },
    setVisibleEvChargerInfo(state, val) { //-查看充电桩
        state.visibleEvChargerInfo = val
    },
    setVisibleAdapterInfo(state, val) { //-查看热泵
        state.visibleAdapterInfo = val
    },
    setVisibleBind(state, val) { //公用--绑定
        state.visibleBind = val
    },
    setVisibleBind2(state, val) { //公用--绑定2
        state.visibleBind2 = val
    },
    setVisibleShow(state, val) { //公用--显示1
        state.visibleShow = val
    },
    setVisibleShow2(state, val) { //公用--显示2
        state.visibleShow2 = val
    },
    setVisibleUserInfo(state, val) { //修改用户资料框
        state.visibleUserInfo = val
    },
    setVisibleReport(state, val) { //报告设置
        state.visibleReport = val
    },
}

export default {
    state,
    mutations
}