/*
 * @Author: your name
 * @Date: 2020-11-23 14:45:10
 * @LastEditTime: 2020-12-13 16:17:18
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solax-design\src\main.js
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import API from './api/content.js'
import axios from 'axios'
import i18n from './lan'
// import 'element-ui/lib/theme-chalk/index.css';
import './assets/style/theme/index.css';
import './assets/css/common.less'
import './assets/css/skin.less'

import directive from "./directive"
Vue.use(directive)
import { Container, Header, Aside, Main, Footer, Button, Select, Table, TableColumn, Menu, Submenu, MenuItem, Dropdown, DropdownMenu, DropdownItem, Form, FormItem, Input, Checkbox, Message, MessageBox, Alert, Breadcrumb, BreadcrumbItem, Pagination, Dialog, Option, Loading, Row, Col, ButtonGroup, Tabs, TabPane, Popconfirm, Tooltip, Drawer, Popover, Link, Tree, Cascader, DatePicker, TimePicker, Radio, RadioGroup, Notification, Upload, Divider, Switch, TimeSelect, Steps, Step, Card, InputNumber, CheckboxGroup,Prompt ,RadioButton} from 'element-ui';
Vue.prototype.$ELEMENT = { size: 'small', zIndex: 3000 };
Vue.use(Container)
Vue.use(Header)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Footer)
Vue.use(Button)
Vue.use(Select)
Vue.use(Option)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Checkbox)
Vue.use(Alert)
Vue.use(Pagination)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Pagination)
Vue.use(Loading)
Vue.use(Row)
Vue.use(Col)
Vue.use(ButtonGroup)
Vue.use(Tabs)
Vue.use(Dialog)
Vue.use(TabPane)
Vue.use(Popconfirm)
Vue.use(Tooltip)
Vue.use(Drawer)
Vue.use(Popover)
Vue.use(Link)
Vue.use(Tree)
Vue.use(TimePicker)
Vue.use(DatePicker)
Vue.use(Cascader)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Upload)
Vue.use(Divider)
Vue.use(Switch)
Vue.use(TimeSelect)
Vue.use(Steps)
Vue.use(Step)
Vue.use(Card)
Vue.use(InputNumber)
Vue.use(CheckboxGroup)
Vue.use(RadioButton)

Vue.config.productionTip = false
Vue.prototype.API = API
Vue.prototype.axios = axios
Vue.prototype.$message = Message;
Vue.prototype.$prompt  = Prompt;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$notify = Notification;
Vue.prototype.Regs = {
    address: /^[\u4E00-\u9FA5A-Za-z0-9,，#&（）()\s-]+$/, //地址
    email_reg: /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,6})$/, //国际邮箱
    //phone_reg:/^[\d|\-|\+][\d|\-|\+|\*|\s]{0,31}$/,//原国际电话
    phone_reg: /^[\d|\-|\+|\s|\#]+$/, //国际电话
    china_reg: /[\u4e00-\u9fa5]/, //中文
    space_reg: /\s/, //不允许出现空格
    loginName_reg: /^[A-Za-z0-9@._-]+$/, //登录账号（只允许出现英文、数字、“@”、“_”、“.”“-”）,
    num_reg: /^\d+(\.\d+)?$/
};


new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')