/*
 * @Author: wangding
 * @Date: 2020-08-27 12:01:27
 * @LastEditTime: 2020-10-21 18:00:47
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \SolaxCloud\src\api\content.js
 * 接口地址-以及使用方法
 */
import * as API from './'
import wd from './wdContent'
import yq from './yqContent'

export default {
    ...wd,
    ...yq,
    //报告设置
    setReport: (params) => {
        return API.POST3('/system/tuser/setReport', params)
    },
    //报告回显
    getReport: (params) => {
        return API.POST3('/system/tuser/getReport', params)
    },
    //上传图片
    upLoadLogo: (params) => {
        return API.POST3('/system/tuser/upLoadLogo', params)
    },
    //获取图片
    getLogo: (params) => {
        return API.POST3('/system/tuser/getLogo', params)
    },
    //个人信息
    updateUser: (params) => {
        return API.POST3('/system/user/update', params)
    },
    //获取国家
    getCountryList: (params) => {
        return API.POST3('/system/user/getAllCountries', params)
    },
    //缓存投资数据
    saveReturnToCache: (params) => {
        return API.POST3('/treturninvestment/saveToCache', params)
    },
    //光伏模块计算
    selectModule: (params) => {
        return API.POST3('/system/tproject/selectModule', params)
    },
    //删除光伏阵列时计算
    deletePvArray: (params) => {
        return API.POST3('/system/tproject/deletePvArray', params)
    },
    tprojectSave: (params) => {
        return API.POST3('/system/tproject/save', params)
    },
    tprojectReoprt: (params) => {
        return API.POST3('/system/tproject/save', params)
    },
    //根据光伏模块获取光伏阵列默认值
    getPvArrayDefaultValue: (params) => {
        return API.POST3('/system/tproject/getPvArrayDefaultValue', params)
    },
    tmanufacturerSave: (params) => {
        return API.POST3('/system/tmanufacturer/save', params)
    },
    tmanufacturerGetEditPage: (params) => {
        return API.POST3('/system/tmanufacturer/getEditPage', params)
    },
    tmanufacturerUpdate: (params) => {
        return API.POST3('/system/tmanufacturer/update', params)
    },
    tmoduleUpdateVisible: (params) => {
        return API.POST3('/system/tmodule/updateVisible', params)
    },
    
    tdictionaryGetDictionaryBatch: (params) => {
        return API.POST3('/system/tdictionary/getDictionaryBatch', params)
    },
    tprojectFillEquipmentCheck: (params) => {
        return API.POST3('/system/tproject/fillEquipmentCheck', params)
    },
    tprojectSelectPvForRecommend: (params) => {
        return API.POST3('/system/tproject/selectPvForRecommend', params)
    },
    tprojectSelectPvRecommand: (params) => {
        return API.POST('/system/tproject/selectPvRecommand', params)
    },
    tprojectBatteryRecommend: (params) => {
        return API.POST3('/system/tproject/batteryRecommend', params)
    },
    tprojectSelectBattery: (params) => {
        return API.POST3('/system/tproject/selectBattery', params)
    },
    getBatteryAvaNum: (params) => {
        return API.POST3('/system/tBatterycompatible/getBatteryAvaNum', params)
    },
    changeRatio: (params) => {
        return API.POST2('/system/tproject/changeRatio', params)
    },
    //获取机型列表
    queryModellist: (params) => {
        return API.POST2('/system/tinverter/listAll', params)
    },
    //新增机型
    AddNewmodels: (params) => {
        return API.POST2('/tregiondev/save', params)
    },
    // 分页查询推荐机型
    queryRecommendedmodel: (params) => {
        return API.POST2('/tregiondev/getPage', params)
    },
    //删除机型
    deletemodels: (params) => {
        return API.POST2('/tregiondev/deleteBatch', params)
    },
}