<!--
 * @Author: your name
 * @Date: 2020-11-24 10:48:19
 * @LastEditTime: 2020-11-24 11:02:26
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solax-design\src\views\login\agreement.vue
-->
<template>
    <div>
        <el-dialog
        title="免责声明"
        :visible.sync="newVisible"
        width="40%"
        :close-on-click-modal='true'
        :before-close="handleClose"
        >
            <p>Suntellite SunnyDesign设计软件用户许可协议</p>
            <p>使用本软件之前，请仔细阅读以下使用许可。在使用本软件的同时，默认您已经接受此协议。如您不同意以下任何一条许可，我们有权要求您停止使用该软件，并对造成的损失不负任何责任。</p>
            <p>本《用户许可协议》(以下称《协议》)是您(个人或机构团体)与上述软件(以下称“软件”或“软件产品”)版权所有人浙大桑尼能源之间的法律协议。在您使用本软件产品之前,请务必阅读此《协议》，任何与《协议》有关的软件、电子文档等都应是按本协议的条款而授予您的，同时本《协议》亦适用于任何有关本软件产品的后期发行和升级。 您一旦安装、复制、下载、访问或以其它方式使用本软件产品，即表示您同意接受本《协议》各项条款的约束。 如您不同意本《协议》的条款，那么，版权所有人浙大桑尼能源则不予将本软件产品的使用权授予您。 在这种情况下，您不得使用、复制或传播本软件产品。</p>
            <p>1. 权利的授予.</p>
            <p>本《协议》授予您安装和使用的权利。</p>
            <p>您必须预先获得版权人授权许可后才能安装和使用本软件产品。</p>
            <p></p>
            <p>本《协议》授予您复制和分发的权利。</p>
            <p>您可以复制、分发该软件产品, 但您必须保证每一份复制和分发都必须是完整和真实的, 包括所有有关本软件产品的软件、电子文档, 版权和商标宣言,及本协议. 但不包括：任何组织或个人利用本软件以任何方式为公众服务并同时满足其自身特定目的而分发和复制本软件产品, 均须得到版权人授权同意后方可进行, 否则视为侵权.</p>
            <p></p>
            <p>2. 其它权利和限制说明</p>
            <p>禁止反向工程、反向编译和反向汇编：</p>
            <p>您不得对本软件产品进行反向工程、反向编译和反向汇编，同时不得改动编译在程序文件内部的任何资源。 除非适用法律明文允许上述活动，否则您必须遵守此协议限制。</p>
            <p></p>
            <p>终止：</p>
            <p>如您未遵守本《协议》的各项条件，在不损害其它权利的情况下，版权人可将本《协议》终止。 且您必须停止使用该“软件产品”，并销毁其各部分的所有副本。</p>
            <p></p>
            <p>商标：</p>
            <p>本《协议》不授予您版权人浙大桑尼能源所拥有的任何商标或服务商标有关的任何权利。</p>
            <p></p>
            <p>使用方式：</p>
            <p>对于个人非商业用途，本软件产品准许个人用户免费使用。凡用于商业用途，均须得到版权人授权许可。</p>
            <p></p>
            <p>3. 版权</p>
            <p>本软件产品（包括但不限于本软件产品中所含的任何内容和附加说明）及本软件产品的任何副本的一切所有权和知识产权，均由版权人浙大桑尼能源拥有。</p>
            <p></p>
            <p>4. 有限保证</p>
            <p>本软件版权人浙大桑尼能源对本软件产品的使用不提供任何保证。 版权人将不对任何用户保证本软件产品的适用性，不保证无故障产生；同时不对任何用户使用此软件所遭遇到的任何理论上的或实际上的损失承担赔偿责任。</p>
            <p></p>
            <p>本软件版权人对本软件产品可能或已知问题造成的意外，连续，直接或间接损失不承担任何责任(包括利益损失，商业中断，信息丢失或其他任何经济上的损失)。</p>
            <p></p>
            <p>5. 其它</p>
            <p>本软件版权人浙大桑尼能源对本协议拥有最终解释权。</p>
            <p></p>
            <p></p>
            <p>© 浙大桑尼能源 版权所有，保留所有权利。</p>
        </el-dialog>
    </div>
</template>

<script>
export default {
    components: {

    },
    props: {

    },
    data() {
        return {

        };
    },
    computed: {
        newVisible(){
            return this.$store.state.sysStore.visibleAdd
        }
    },
    watch: {

    },
    created() {

    },
    mounted() {

    },
    methods: {
        handleClose(){
            this.$store.commit('setVisibleAdd',false)
        }
    },
};
</script>

<style  lang="less" scoped>

</style>
