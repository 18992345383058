/*
 * @Author: wangding
 * @Date: 2020-10-19 17:13:25
 * @LastEditTime: 2020-12-13 18:18:38
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solaxcloud\src\api\wdContent.js
 */
import * as API from './'
export default {
    //登录
    systemLogin: (params) => {
        return API.POST3('/system/login', params)
    },
    //注册
    systemRegisterUser: (params) => {
        return API.POST3('/system/registerUser', params)
    },
    systemConfirm: (params) => {
        return API.GET('/system/confirm', params)
    },
    systemForgetPassword: (params) => {
        return API.POST3('/system/forgetPassword', params)
    },
    systemEnterNewPassWord: (params) => {
        return API.POST3('/system/enterNewPassWord', params)
    },
    systemResetPassword: (params) => {
        return API.POST3('/system/resetPassword', params)
    },
    systemUpdatePassword: (params) => {
        return API.POST3('/system/updatePassword', params)
    },
    systemTinverterGetPage: (params) => {
        return API.POST3('/system/tinverter/getPage', params)
    },
    systemTinverterSave: (params) => {
        return API.POST3('/system/tinverter/save', params)
    },
    systemTinverterGetById: (params) => {
        return API.POST3('/system/tinverter/getById', params)
    },
    systemTuserGetLogo: (params) => {
        return API.POST3('/system/tuser/getLogo', params)
    },
    systemTinverterUpdate: (params) => {
        return API.POST3('/system/tinverter/update', params)
    },
    systemTinverterDelete: (params) => {
        return API.POST3('/system/tinverter/delete', params)
    },
    systemTinverterGetList: (params) => {
        return API.POST3('/system/tinverter/listAll', params)
    },
    systemTinverterDeleteBatch: (params) => {
        return API.POST3('/system/tinverter/deleteBatch', params)
    },
    systemTinverterImport: (params) => {
        return API.POST3('/system/tinverter/importTInverters', params)
    },
    systemTModuleGetPage: (params) => {
        return API.POST3('/system/tmodule/getPage', params)
    },
    systemModuleDelete: (params) => {
        return API.POST3('/system/tmodule/delete', params)
    },
    systemModuleDeleteBatch: (params) => {
        return API.POST3('/system/tmodule/deleteBatch', params)
    },
    systemModuleGetById: (params) => {
        return API.POST3('/system/tmodule/getById', params)
    },
    systemModuleUpdate: (params) => {
        return API.POST3('/system/tmodule/update', params)
    },
    systemModuleSave: (params) => {
        return API.POST3('/system/tmodule/save', params)
    },
    systemModuleImport: (params) => {
        return API.POST3('/system/tmodule/import', params)
    },
    systemTMoudleGetList: (params) => {
        return API.POST3('/system/tmodule/manufacturer', params)
    },
    systemTManufacturerGetList: (params) => {
        return API.POST3('/system/tmanufacturer/getList',params)
    },
    systemTMaterialGetList: () => {
        return API.POST3('/system/tmaterial/getList')
    },
    systemTCityGetPage: (params) => {
        return API.POST3('/system/tcity/getPage', params)
    },
    systemTCityImport: (params) => {
        return API.POST3('/system/tcity/importTCities', params)
    },
    systemTCityGetById: (params) => {
        return API.POST3('/system/tcity/getById', params)
    },
    systemTCityUpdate: (params) => {
        return API.POST3('/system/tcity/update', params)
    },
    systemTCitySave: (params) => {
        return API.POST3('/system/tcity/save', params)
    },
    systemCityDeleteBath: (params) => {
        return API.POST3('/system/tcity/deleteBatch', params)
    },
    systemCityGetAll: (params) => {
        return API.POST3('/system/tcity/allCity', params)
    },
    systemRegionsGetAll: (params) => {
        return API.POST3('/system/tcountry/getAllRegions', params)
    },
    systemCountryGetAll: (params) => {
        return API.POST3('/system/tcity/getAllByRegion', params)
    },
    systemTCountryGetPage: (params) => {
        return API.POST3('/system/tcountry/getPage', params)
    },
    systemTCountryDeleteBath: (params) => {
        return API.POST3('/system/tcountry/deleteBatch', params)
    },
    systemTCountryGetById: (params) => {
        return API.POST3('/system/tcountry/getById', params)
    },
    systemTCountryUpdate: (params) => {
        return API.POST3('/system/tcountry/update', params)
    },
    systemTCountrySave: (params) => {
        return API.POST3('/system/tcountry/save', params)
    },
    projectListGetPage: (params) => {
        return API.POST3('/system/tproject/getPage', params)
    },
    projectGetById: (params) => {
        return API.POST3('/system/tproject/getById', params)
    },
    projectDetailGetById: (params) => {
        return API.POST3('/system/tproject/editProject', params)
    },
    projectListDeleteBatch: (params) => {
        return API.POST3('/system/tproject/deleteBatch', params)
    },
    systemTUserGetPage: (params) => {
        return API.POST3('/system/tuser/getPage', params)
    },
    systemTUserDeleteBath: (params) => {
        return API.POST3('/system/tuser/deleteBatch', params)
    },
    systemTUserGetAdminPage: (params) => {
        return API.POST3('/system/tuser/getAdminPage', params)
    },
    updateTUserPwdBath: (params) => {
        return API.POST3('/system/tuser/resetPwd', params)
    },
    systemTDictionary: (params) => {
        return API.POST3('/system/tdictionary/getDictionary', params)
    },
    tinverterGetByInverterId: (params) => {
        return API.POST3('/system/tinverter/getByInverterId', params)
    },
    tprojectPvRecommend: (params) => {
        return API.POST3('/system/tproject/pvRecommend', params)
    },
    tprojectUpdateProject: (params) => {
        return API.POST3('/system/tproject/updateProjectInfo', params)
    },
    tprojectDeleteInverter: (params) => {
        return API.POST3('/system/tproject/deleteInverter', params)
    },
    tprojectSelectInverter: (params) => {
        return API.POST3('/system/tproject/selectInverter', params)
    },
    tprojectSave: (params) => {
        return API.POST3('/system/tproject/save', params)
    },
    tprojectSettingACLine: (params) => {
        return API.POST3('/system/tproject/settingLine', params)
    },
    tcrosssectionList: (params) => {
        return API.GET('/system/tcrosssection/list', params)
    },
    tprojectCreateProject: (params) => {
        return API.POST3('/system/tproject/createProject', params)
    },
    tmanufacturerGetPage: (params) => {
        return API.POST3('/system/tmanufacturer/getPage', params)
    },
    saveName: (params) => {
        return API.POST3('/system/tmanufacturer/update', params)
    },
    tmanufacturerDeleteBatch: (params) => {
        return API.POST3('/system/tmanufacturer/deleteBatch', params)
    },
    // 电池
    // 批量删除
    tbatteryDeleteBatch: (params) => {
        return API.POST3('/system/tbattery/deleteBatch', params)
    },
    // 分页列表
    tbatteryGetPage: (params) => {
        return API.POST3('/system/tbattery/getPage', params)
    },
    // 查询
    tbatteryGetById: (params) => {
        return API.POST3('/system/tbattery/getById', params)
    },
    // 获取电池L所有数据
    tbatteryListAll: (params) => {
        return API.GET1('/system/tbattery/listAll', params)
    },
    // 导入电池
    tbatteryImportTInverters: (params) => {
        return API.POST3('/system/tbattery/importTInverters', params)
    },
    // 保存
    tbatterySave: (params) => {
        return API.POST3('/system/tbattery/save', params)
    },
    // 更新
    tbatteryUpdate: (params) => {
        return API.POST3('/system/tbattery/update', params)
    },

    // 充电桩
    // 获取所有充电桩数据
    tchargepileListAll: (params) => {
        return API.POST3('/system/tchargepile/listAll', params)
    },
    // 分页列表
    tchargepileGetPage: (params) => {
        return API.POST3('/system/tchargepile/getPage', params)
    },
    // 查询
    tchargepileGetById: (params) => {
        return API.POST3('/system/tchargepile/getById', params)
    },
    // 保存
    tchargepileSave: (params) => {
        return API.POST3('/system/tchargepile/save', params)
    },
    // 更新
    tchargepileUpdate: (params) => {
        return API.POST3('/system/tchargepile/update', params)
    },
     // 批量删除
    tchargepileDeleteBatch: (params) => {
        return API.POST3('/system/tchargepile/deleteBatch', params)
    },

    // 热泵
    // 获取所有热泵数据
    theatpumpListAll: (params) => {
        return API.POST3('/system/theatpump/listAll', params)
    },
    // 分页列表
    theatpumpGetPage: (params) => {
        return API.POST3('/system/theatpump/getPage', params)
    },
    // 查询
    theatpumpGetById: (params) => {
        return API.POST3('/system/theatpump/getById', params)
    },
    // 保存
    theatpumpSave: (params) => {
        return API.POST3('/system/theatpump/save', params)
    },
    // 更新
    theatpumpUpdate: (params) => {
        return API.POST3('/system/theatpump/update', params)
    },
     // 批量删除
     theatpumpDeleteBatch: (params) => {
        return API.POST3('/system/theatpump/deleteBatch', params)
    },


    // 电池兼容性
    // 分页列表
    tBatterycompatibleGetPage: (params) => {
        return API.POST3('/system/tBatterycompatible/getPage', params)
    },
    // 保存
    tBatterycompatibleSave: (params) => {
        return API.POST3('/system/tBatterycompatible/save', params)
    },
    // 更新
    tBatterycompatibleUpdate: (params) => {
        return API.POST3('/system/tBatterycompatible/update', params)
    },
     // 批量删除
     tBatterycompatibleDeleteBatch: (params) => {
        return API.POST3('/system/tBatterycompatible/deleteBatch', params)
    },

    // 其他设备
    // 分页列表
    otherdeviceGetPage: (params) => {
        return API.GET1('/otherdevice/getPage', params)
    },
    // 保存
    otherdeviceSave: (params) => {
        return API.POST3('/otherdevice/save', params)
    },
    // 更新
    otherdeviceUpdate: (params) => {
        return API.POST3('/otherdevice/update', params)
    },
     // 批量删除
     otherdeviceDeleteBatch: (params) => {
        return API.POST3('/otherdevice/deleteBatch', params)
    },
     // 获取设备参数
     otherdevicegetDeviceParam: (params) => {
        return API.POST3('/otherdevice/getDeviceParam', params)
    },
}